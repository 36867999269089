<template>
  <div class="web">
    <MainLayout>
      <template v-slot:content>
        <section>
          <h2>Websites.</h2>

          <p>
            Het web veroveren met toffe, snelle en unieke websites, dat is ons
            doel! <br />We maken nooit gebruik van standaard templates.
            <br />
            Iedere site die we bouwen is uniek. <br /><br />
            Tijdens het ontwikkelen werken we met de laatste standaarden op het
            gebied van webontwikkeling, zodat jij met een state-of-the-art
            website de lucht in gaat. <br /><br />
            Lyghtning heeft een eigen ontwikkeld CMS (content management
            system), welke gekoppeld wordt aan je website. <br />
            Met dit systeem beheer je eenvoudig álle content op jouw website.
            <br /><br />

            Benieuwd wat we al hebben gedaan? <br />
            Check ons portfolio met de tag
            <span class="pink">development</span>.
          </p>

          <router-link class="btn" to="/portfolio"
            >Bekijk ons portfolio</router-link
          >
        </section>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";

export default {
  name: "Web",
  components: {
    MainLayout,
  },
};
</script>